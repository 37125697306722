.App {
  text-align: center;
}

.address {
 vertical-align: "middle";
  padding-left: 6px;
  padding-bottom: 6px;
  font-size: 1.1em;
}
.ant-page-header-heading-left {
  align-items: baseline !important;
}
.ant-page-header-heading-title {
  font-size: 24px !important;
}

.ant-menu {
  font-size: 24px !important;
  background-color: transparent !important;
}

.row {
  width: 100%;
}

.column {
  float: left;
  width: 50%; 
  margin-top: 48px;
}

.leftCol {
  float: right;
  width: 600px;
  margin-right: 12px;
}

.rightCol {
  float: left;
  width: 600px;
  margin-left: 12px;
}

.ant-page-header-footer {
  margin-top: 8px !important;
}

@media only screen and (max-width: 960px) {
  .column {
    width: 100%; 
  }
  .leftCol {
    float: none;
    width: 600px;
    margin: auto;
  }
  .rightCol {
    float: none;
    width: 600px;
    margin: auto;
  }
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 22px;
  }
  h5 {
    font-size: 20px;
  }
  h6 {
    font-size: 18px;
  }
  div {
    font-size: 18px;    
  }
  .ant-card-head-title {
    font-size: 20px !important;    
  }
  .ant-card-extra {
    font-size: 18px !important;    
  }
  .ant-btn-lg {
    font-size: 22px !important;
    height: 60px !important;
  }
  .ant-page-header-heading-title {
    font-size: 30px !important;
  }
  .ant-page-header-heading-sub-title {
    font-size: 18px !important;
    display: none;
  }
}
